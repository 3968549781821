<template>
  <div class="bg-main">
    <Header :back="true" />
    <v-container>
      <v-row class="px-3">
        <v-col cols="12">
          <img
            class="pr-3"
            style="position: relative; top: 4px"
            src="@/assets/image/icon-warning-blue.png"
            alt=""
          />
          <span style="font-size: 20px; font-weight: bold">แจ้งปัญหา</span>
        </v-col>
        <v-col cols="12">
          <span style="font-size: 14px; font-weight: bold">ภาพเหตุการณ์</span>
        </v-col>
        <v-col cols="12" class="py-0">
          <take-photo @img="saveImage" />
        </v-col>
        <v-col cols="12">
          <span style="font-size: 14px; font-weight: bold">ข้อความ</span>
        </v-col>
        <v-col cols="12" class="py-0">
          <textarea
            v-model="text"
            class="text-box-tnl"
            placeholder="โปรดระบุข้อความ"
          />
        </v-col>
      </v-row>
      <v-row style="position: fixed; bottom: 3%; width: 100%">
        <v-col class="mx-auto px-6">
          <v-btn
            @click="saveIssue"
            color="#62C4E3"
            style="width: 100%; border-radius: 15px; height: 70px"
          >
            <img class="pr-3" src="@/assets/image/icon-warning.png" />
            <span style="color: white; font-size: 24px">แจ้งปัญหา</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "@/components/header";
import TakePhoto from "@/components/takePhoto";
export default {
  name: "problem",
  components: { TakePhoto, Header },
  data() {
    return {
      image: [],
      text: "",
    };
  },
  methods: {
    saveImage(val) {
      this.image = val;
    },
    saveIssue() {
      // check validate input
      if (this.image.length == 0) {
        this.$swal({
          icon: "warning",
          title: "Waring!",
          text: "กรุณาถ่ายภาพเหตุการณ์",
        });
      } else if (this.text == "") {
        this.$swal({
          icon: "warning",
          title: "Waring!",
          text: "กรุณาระบุข้อความ",
        });
      } else {
        this.globalLoading();
        let data = {
          image: this.image[0].image,
          detail: this.text,
        };
        this.$store.dispatch("common/issue", data).then((res) => {
          if (res.status == "success") {
            this.$swal({
              icon: "success",
              title: "",
              text: "ส่งข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 4000,
            }).then(() => {
              this.$router.push("Main");
              this.globalHideLoading();
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped></style>
